




































































































































import Vue from "vue";
import zxcvbn, { ZXCVBNResult } from "zxcvbn";

import { post, t } from "#root/lib/utils";
import { MainAlertButton, MainAlertData } from "#root/lib/interfaces";
const LanguageButton = () =>
  import("#root/components/generic/LanguageButton.vue");

import { EmailCredentials, AuthSession, UserProfile } from "#mws/interfaces";
import { Endpoints, LocaleKeys } from "#mws/data";
import { Rules } from "#mws/data";
import AppModule from "#root/store/app";
import SessionModule from "#root/store/session";
import PersistentModule from "#root/store/persistent";
import Store from "#root/store/store";

export default Vue.extend({
  name: "LoginPanel",
  components: { LanguageButton },
  methods: {
    t: t,
    localeChanged: function () {
      this.formActive = false;
      this.$nextTick(() => {
        this.formActive = true;
      });
    },
    registerButton: function () {
      post<EmailCredentials, string>({
        endpoint: Endpoints.HTTP.auth.email.register,
        data: {
          email: this.register.email,
          password: this.register.password,
        },
      }).then((message) => {
        AppModule.showAlert({
          title: { key: LocaleKeys.server.auth.email.validationNeeded },
          message: { key: message },
          buttons: [
            {
              text: { key: LocaleKeys.alert.ok },
              color: "primary",
              action: AppModule.hideAlert,
            },
          ],
        });
      });
    },
    loginButton: function () {
      post<EmailCredentials, AuthSession>({
        endpoint: Endpoints.HTTP.auth.email.login,
        data: {
          email: this.login.email,
          password: this.login.password,
          remember: this.login.remember,
        },
      }).then((authSession) => {
        PersistentModule.session = authSession;
        this.connect();
      });
    },
    connect(): void {
      post<null, UserProfile | null>({
        endpoint: Endpoints.HTTP.user.profile.get,
        auth: true,
      })
        .then((result) => {
          if (result) {
            SessionModule.profile = result;
          }
          this.$router.push("/menu");
        })
        .catch((error) => {
          if (error === LocaleKeys.server.auth.error.invalidToken) return;

          AppModule.setAlertButton(
            {
              text: { key: LocaleKeys.alert.retry },
              color: "primary",
              action: this.connect,
            },
            0
          );
          AppModule.setAlertButton(
            {
              text: { key: LocaleKeys.alert.logout },
              color: "error",
              action: () => {
                Store.logout();
                this.$router.push("/login");
                AppModule.loadingAlert();
              },
            },
            1
          );
        });
    },
  },
  computed: {
    passwordZxcvbn(): ZXCVBNResult {
      return zxcvbn(this.register.password);
    },
    passwordProgress(): number {
      return (this.passwordZxcvbn.score / 4.0) * 100.0;
    },
    passwordFeedback(): string {
      return this.passwordZxcvbn.feedback.warning || "";
    },
    passwordColor(): string {
      return ["error", "warning", "success"][
        Math.floor(this.passwordProgress / 40)
      ];
    },
    emailRules() {
      return this.formActive
        ? [
            (value: string) =>
              Rules.EMAIL.REGEX.test(value) ||
              t(LocaleKeys.fieldError.emailInvalid),
          ]
        : [];
    },
    passwordRules() {
      return this.formActive
        ? [
            (value: string) =>
              value.length >= Rules.PASSWORD.MIN_CHAR ||
              t(LocaleKeys.fieldError.minCharacters, [Rules.PASSWORD.MIN_CHAR]),
            (value: string) =>
              value.length <= Rules.PASSWORD.MAX_CHAR ||
              t(LocaleKeys.fieldError.maxCharacters, [Rules.PASSWORD.MAX_CHAR]),
          ]
        : [];
    },
    repeatPasswordRules() {
      return this.formActive
        ? [
            (value: string) =>
              (value.length > 0 && value === this.register.password) ||
              t(LocaleKeys.fieldError.passwordMismatch),
          ]
        : [];
    },
    notEmptyRule() {
      return this.formActive
        ? [
            (value: string) =>
              value.length > 0 || t(LocaleKeys.fieldError.empty).toString(),
          ]
        : [];
    },
  },
  data: () => ({
    lk: LocaleKeys,
    formActive: true,
    login: {
      email: "",
      password: "",
      valid: false,
      remember: false,
    },
    register: {
      email: "",
      password: "",
      repeatPassword: "",
      valid: false,
    },
    showRegisterPassword: false,
    showRepeatRegisterPassword: false,
    tab: "login",
  }),
});
